import React, { useState }  from 'react';
import TMDialog from '../../../lib/Dialog/TMDialog';
import useIntl from '../../../hooks/useIntl';
import { ItsmeConfirmButton } from './ItsmeConfirmButton';
import AlfapassButton from './AlfapassButton';

const IdentifyDriverDialog = ({ driverToIdentify, openIdentify, handleDialogClose }) => {

	const { translate } = useIntl();

  return (
    <TMDialog
      key={'identify-driver'}
      title={translate("identity.driver.select.title", { firstName: driverToIdentify.firstName, lastName: driverToIdentify.lastName })}
      dialogOpen={openIdentify}
      handleDialogClose={handleDialogClose}
      maxWidth="sm"
      showBottomClose={false}
    >
      <div style={{textAlign: 'center' }}>
        <p style={{fontSize: '1.25em'}}>{translate("identity.driver.select.heading")}</p>
        <p><AlfapassButton driver={driverToIdentify} handleDialogClose={handleDialogClose}/></p>
        <p><ItsmeConfirmButton driver={driverToIdentify}/></p>
      </div>
    </TMDialog>
  );
};

export default IdentifyDriverDialog;