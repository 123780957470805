import React, { useReducer, useState } from 'react';
import { useSnackbar } from 'notistack';
import client from '../../../feathers';
import useIntl from '../../../hooks/useIntl';
import TMDialog from '../../../lib/Dialog/TMDialog';
import TMForm from '../../../lib/Form/TMForm';
import { useAlertDialog } from '../../../context/AlertDialogProvider';
import useConfirmDialog from '../../../hooks/useConfirmDialog';

const AlfapassButton = ({ driver, handleDialogClose }) => {

	const [state, dispatch] = useReducer(reducer, initialState);
	const [openAddAlfapass, setOpenAddAlfapass] = useState(false);

	const { translate } = useIntl();
	const { showAlert } = useAlertDialog();
  const { enqueueSnackbar } = useSnackbar();

	const handleChange = (e) => {
    dispatch({ payload: { 
    	[e.target.name]: e.target.value
    }})
  };

  const confirmAlfapass = async () => {
  	showConfirmDialog('');
  }

	const handleSubmitAlfapass = async () => {
		console.log('HERE')
		try {
			const result = await client.service('drivers').patch(driver.id, { ...driver, alfaPass: state.alfaPass });
			console.log(result);
			enqueueSnackbar(`Alfapass saved successfully for ${driver.firstName} ${driver.lastName}`)
			handleDialogClose();
		} catch (e) {
			console.error(e);
			showAlert("There was an error", e.message, "xs");
		}
	}

	const { showConfirmDialog } = useConfirmDialog(
		'driver.edit', 'driver.edit.alfapass.warning', 
		handleSubmitAlfapass, handleDialogClose,
		'general.ok', 'general.cancel'
	)
  
  return (
		<div style={{cursor: 'pointer'}} onClick={() => setOpenAddAlfapass(true) }>
			<img src="/assets/alfapass-logo.png" width='350'/> 

			<TMDialog
	      key="add-alfapass"
	      title={`Add Alfapass for ${driver.firstName} ${driver.lastName}`}
	      dialogOpen={openAddAlfapass}
	      handleDialogClose={handleDialogClose}
	      maxWidth="sm"
	      showBottomClose={false}
	    >
	      <TMForm
	        object={state}
	        handleChange={handleChange}
	        handleSubmit={confirmAlfapass}
	        submitLabel={"SAVE"}
	        handleCancel={handleDialogClose}
	        cancelLabel={translate("general.cancel")}
	        fields={[
	    			{ field: 'alfaPass', required: true, label: 'AlfaPass', type: 'number', minLength: 5, maxLength: 6, helperText: translate("driver.alfapass.helper") },
	        ]}
	      />
	    </TMDialog>
    </div>
  );
}

const initialState = {
  alfaPass: '',
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState }
    default:
      return { ...state, ...payload }
  }
}

export default AlfapassButton;