import React, { useState, useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { pickBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import TMForm from '../lib/Form/TMForm';
import client from '../feathers';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BarChartIcon from '@material-ui/icons/BarChart';
import useIntl from '../hooks/useIntl';
import TMDataTable from '../lib/Table/TMDataTable';
import TMDialog from '../lib/Dialog/TMDialog';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { useAlertDialog } from '../context/AlertDialogProvider';
import useConfirmDialog from '../hooks/useConfirmDialog';
import useSentry from '../hooks/useSentry';
import useAuth from '../hooks/useAuth';
import IdentifyDriverDialog from '../components/releases-transporters/drivers/IdentifyDriverDialog';
import AddTransporterDialog from '../components/releases-transporters/AddTransporterDialog.jsx';
import { useSuccessRateOfSecurePickup } from '../hooks/useSuccessRateOfSecurePickup';
import { addtoUserAuditTrail } from '../util/userAuditTrail';
import { Http } from '../axios';

const useStyles = makeStyles((theme) => ({
  hoverButton: {
    color: '#757575',
    '&:hover': {
    	color: theme.palette.primary.main
    }
  },
  inactive: {
    '& td': {
      color: theme.palette.secondary.main,
    }
  },
}));

export default function DriversOverview() {
	
	const [openAdd, setOpenAdd] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openIdentify, setOpenIdentify] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { translate } = useIntl();
	const classes = useStyles();
	const logSentry = useSentry();
  const { user } = useAuth();
  const  { successRateOfSecurePickupJsx, openSrsPickupDialog }  = useSuccessRateOfSecurePickup('drivers');
	const history = useHistory();

	const fetchDrivers = async () => {
    // check for itsme action first
		await postItsmeCode();
		// then load the drivers
		const result = await client.service('drivers').find();
    dispatch({ payload: { drivers: result.data } })
  };

  const postItsmeCode = async() => {
    const itsmeCode = new URLSearchParams(window.location.search).get('code');
    const stateCode = new URLSearchParams(window.location.search).get('state');

    if (stateCode) {
    	if (itsmeCode) {
	      const response = await Http.post('/driver/itsme', { stateCode, itsmeCode });
	      showAlert('itsme® registration successful', 'Received: ' + JSON.stringify(response.data), 'sm');
	    } else {
	      showAlert('Error during itsme® registration', 'Missing code in parameters. Please restart your itsme® verification.', 'sm');
	    }
	    history.push('/drivers')
		} else {
      // nothing itsme-ish going on, so just continue
	  }
  }

  useEffect(() => {
    fetchDrivers();
  }, []);

  const columns = [
		'Last Name',
		'First Name',
		'Email',
		'Mobile phone',
		'AlfaPass Number',
		'itsme®',
		{ 
			name: 'Action',
			options: { filter: false, download: false, viewColumns: false }
		},
		{
      name: "isActive",
      options: { display: false, filter: false, download: false, viewColumns: false }
    }, 
	];

	const getData = () => {
		return state.drivers.map(d => [
			d.lastName, 
			d.firstName, 
			d.email, 
			d.phone, 
			d.alfaPass,
      d.itsmeCode,
			<React.Fragment>
				<IconButton className={classes.hoverButton} onClick={() => openEditDriver(d)}><EditIcon/></IconButton>
				<IconButton className={classes.hoverButton} onClick={() => openIdentifyDriver(d)}><AccountBoxIcon/></IconButton>
			</React.Fragment>,
			d.isActive
		])
	}

	const openEditDriver = async (driver) => {
    dispatch({ payload: {
    	firstName: driver.firstName,
			lastName: driver.lastName,
			email: driver.email,
			phone: driver.phone,
			alfaPass: driver.alfaPass,
			isActive: driver.isActive,
			organizationAddress: driver.organization_address,
			id: driver.id
    }});
    setOpenEdit(true);
  };

  const openIdentifyDriver = async (driverToIdentify) => {
  	dispatch({ payload: { driverToIdentify }});
  	setOpenIdentify(true);
  }

  const handleDialogClose = async () => {
    await fetchDrivers();
    dispatch({ type: 'RESET' });
  	openEdit && setOpenEdit(false);
  	openAdd && setOpenAdd(false);
  	openIdentify && setOpenIdentify(false);
  }

	const handleChange = (e) => {
    dispatch({ payload: { 
    	[e.target.name]: (e.target.hasOwnProperty('checked') ? e.target.checked : e.target.value) 
    }})
  };

  const checkEdit = async () => {
  	const currentDriver = state.drivers.find(d => d.id === state.id);
  	// check if driver was inactivated 
  	if (!state.isActive && currentDriver.isActive) {
  		showConfirmDialog('');
  	} else {
  		handleEditDriver();
  	}
  }

	const handleEditDriver = async () => {
    let didNotError = true;
    try {
    	showProgressDialog();
			const data = {
				firstName: state.firstName, 
				lastName: state.lastName, 
				email: state.email, 
				phone: state.phone,
				alfaPass: state.alfaPass, 
				isActive: state.isActive
			};
		  const result = await client.service('drivers').patch(state.id, data);
    	const currentDriver = state.drivers.find(d => d.id === state.id);
    	addtoUserAuditTrail(
				'edit driver', 
				{ 
					id: state.id, 
					old: pickBy(currentDriver, (v,k) => (data.hasOwnProperty(k) && data[k] !== v)), // only add the changed values 
					new: pickBy(data, (v,k) => currentDriver[k] !== v) // only add the changed values
				}
			);

    	// check if driver was inactivated or alfapass number changed
    	if ((!state.isActive && currentDriver.isActive) || (state.alfaPass !== currentDriver.alfaPass)) {
    		enqueueSnackbar(translate('driver.edit.warning.unassigning'), { persist: true });
    		await window.walletApi.unassignReleases(
          result.removedReleases.map(({release}) => ({ address: release.address, version: release.version })),
          { from: user.organization }
        );
      	closeSnackbar();
    	}
  		enqueueSnackbar(translate('driver.patched'), { autoHideDuration: 1500 });
    } catch (error) {
      logSentry(error);
    	showAlert(translate('general.error'), error.message, "sm");
    	didNotError = false;
    } finally {
      didNotError && await fetchDrivers();
      handleDialogClose();
      hideProgressDialog();
    }
	}

	const { showConfirmDialog } = useConfirmDialog(
		'driver.edit', 'driver.edit.inactive.warning', 
		handleEditDriver, handleDialogClose,
		'general.ok', 'general.cancel'
	)

	const setRowProps = (row, dataIndex) => {
    // last field = isActive
    if(row[row.length-1] === false) return { className: classes.inactive }
  };

	return (
		<React.Fragment>
			<div className="drivers-table">
				<TMDataTable
		      title={'Drivers'}
		      columns={columns}
		      data={getData()}
		      setRowProps={setRowProps}
		      options={{
			      print: false,
			      filter: false,
			      viewColumns: false,
			      customToolbar: () => (
              <React.Fragment>
                <IconButton
                  className={classes.hoverButton}
                  onClick={() => openSrsPickupDialog()}
                  >
                  <BarChartIcon />
                </IconButton>
                <IconButton
	                className={classes.hoverButton}
	                onClick={() => setOpenAdd(true)}
	                >
	                <CreateIcon />
                </IconButton>
              </React.Fragment>
				    ),
		      }}
		    />
	    </div>

	    <AddTransporterDialog
				type={'driver'} 
				openAdd={openAdd} 
				handleDialogClose={handleDialogClose}
			/>

			<TMDialog
	      key="edit-driver"
	      title={"Edit driver"}
	      dialogOpen={openEdit}
	      handleDialogClose={handleDialogClose}
	      maxWidth="md"
	      showBottomClose={false}
	    >
	      <TMForm
	        object={state}
	        handleChange={handleChange}
	        handleSubmit={checkEdit}
	        submitLabel={translate("driver.edit")}
	        handleCancel={handleDialogClose}
	        cancelLabel={translate("general.cancel")}
	        fields={[
	    			{ field: 'firstName', label: 'First name', required: true }, 
	    			{ field: 'lastName', label: 'Last name', required: true }, 
	    			{ field: 'email', label: 'Email', isEmail: true },
	    			{ field: 'phone', label: 'Phone', type: 'tel' },
	    			{ field: 'isActive', label: 'Active ?', componentType: Switch },
	        ]}
	      />
	    </TMDialog>

    	<IdentifyDriverDialog 
    		driverToIdentify={state.driverToIdentify}
    		openIdentify={openIdentify}
    		handleDialogClose={handleDialogClose}
    	/>

			{/* Start - Success Rate of Secure Pickup Dialog */}
	   	{ successRateOfSecurePickupJsx }
	  	{/* End - Success Rate of Secure Pickup Dialog */}
	
		</React.Fragment>
	);
}

const initialState = {
  drivers: [],
  driverToIdentify: {},
  id: undefined,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  alfaPass: '',
  isActive: false,
  organizationAddress: '',
  stateCode: '',
  itsmeCode: '',
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState, drivers: state.drivers }
    default:
      // = SET
      return { ...state, ...payload }
  }
}
