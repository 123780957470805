import React, { createContext, useReducer, useEffect, useState } from 'react';
import app from '../feathers';
import reducer from '../util/reducer';

import { IntlProvider } from 'react-intl';
import { SnackbarProvider } from 'notistack';
import Layout from '../pages/Layout';
import messages_en from '../translations/en.json';
import messages_fr from '../translations/fr.json';
import messages_nl from '../translations/nl.json';
import message_de from '../translations/de.json';
import message_it from '../translations/it.json';
import TMWithRoot from '../lib/Theme/TMWithRoot';
import { ProgressBarProvider } from '../context/ProgressBarProvider';
import { LanguageProvider, LanguageContext } from '../context/LanguageProvider';
import { DrawerProvider } from '../context/DrawerProvider';
import { DialogProvider } from '../context/DialogProvider';
import { ImprovedDialogProvider } from '../context/ImprovedDialogProvider';
import { ProgressDialogProvider } from '../context/ProgressDialogProvider';
import { AlertDialogProvider } from '../context/AlertDialogProvider';

const languages = {
  de: message_de,
  en: messages_en,
  fr: messages_fr,
  it: message_it,
  nl: messages_nl,
};

const AuthenticationContext = createContext();

const initialState = {
  accessToken: null,
  user: null,
};

const AuthenticationProvider = (props) => {
  const [{ accessToken, user }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    checkAuthentication();
  }, [!!accessToken]);

  const checkAuthentication = async () => {
    if (window.localStorage.getItem('tmining_wallet') !== null) {
      // console.log("Authenticating ...")
      const { accessToken, user } = await app.authenticate();
      console.log("Found", user)
      dispatch({ payload: { accessToken, user } });
    }
  };

  // trigger authentication
  const resetAuthentication = () => {
    if (window.localStorage.getItem('tmining_wallet') !== null) {
      // console.log("Authenticating ...")
      app.authenticate().then(auth => {
        console.log("Found", auth)
        dispatch({ payload: { accessToken: auth.accessToken, user: auth.user } });
      }).catch(error => {
        console.error(error);
      });
    } else {
      console.log("tmining_wallet not present in local storage")
    }
  };

  return (
    <AuthenticationContext.Provider value={{ accessToken, user }}>
      <ProgressBarProvider>
        <LanguageProvider>
          <LanguageContext.Consumer>
            {(value) => (
              <IntlProvider
                locale={value.language}
                messages={languages[value.language]}
              >
                <DrawerProvider>
                  <DialogProvider>
                    <ImprovedDialogProvider>
                      <ProgressDialogProvider>
                        <AlertDialogProvider>
                          <SnackbarProvider maxSnack={5} hideIconVariant preventDuplicate>
                            <Layout resetAuthentication={resetAuthentication}/>
                          </SnackbarProvider>
                        </AlertDialogProvider>
                      </ProgressDialogProvider>
                    </ImprovedDialogProvider>
                  </DialogProvider>
                </DrawerProvider>
              </IntlProvider>
            )}
          </LanguageContext.Consumer>
        </LanguageProvider>
      </ProgressBarProvider>
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationProvider };
