
export const INITIAL_STATE = {
  loading: false,
  drivers: [],
  barges: [],
  trains: [],
  identification: undefined,
  identificationType: undefined,
  visitNumber: '',
  activeTab: 'driver',
  warningTitle: '',
  warningText: '',
  selectFirstDriver: false,
  selectFirstBarge: false,
  selectFirstTrain: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DRIVERS':
      return { ...state, drivers: action.payload };

    case 'SET_IDENTIFICATION':
      return { ...state, identification: action.payload };

    case 'SET_IDENTIFICATION_TYPE':
      return { ...state, identificationType: action.payload };

    case 'SET_VISITNUMBER':
      return { ...state, visitNumber: action.payload };

    case 'SET_BARGES':
      return { ...state, barges: action.payload };

    case 'SET_TRAINS':
      return { ...state, trains: action.payload };

    case 'SET_ACTIVE_TAB':
      return { ...state, activeTab: action.payload };

    case 'SET_WARNING_TITLE': 
      return { ...state, warningTitle: action.payload };

    case 'SET_WARNING_TEXT': 
      return { ...state, warningText: action.payload };  

    case 'SET_SELECT_FIRST_DRIVERS': // 'driverS' for convenience 
      return { ...state, selectFirstDriver: action.payload };

    case 'SET_SELECT_FIRST_BARGES': // 'bargeS' for convenience
      return { ...state, selectFirstBarge: action.payload };

    case 'SET_SELECT_FIRST_TRAINS': // 'trainS' for convenience
      return { ...state, selectFirstTrain: action.payload };

    case 'RESET':
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
