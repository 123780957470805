import React, { useRef } from 'react';
import { Button, Box } from '@material-ui/core';
import useImprovedDialog from './useImprovedDialog';
import useIntl from './useIntl';

const useConfirmDialog = (titleKey, contentKey, onOk, onCancel, okLabel='general.yes', cancelLabel='general.no', canClose=false) => {
  const { translate } = useIntl();
  const { setDialog, showDialog } = useImprovedDialog();
  const data = useRef();

  // attributes passed for 'pincode.confirm.text'
  const content = translate(contentKey, {
    warning: (<b>WARNING!</b>),
    url: (<a href='https://www.securecontainerrelease.com/nopincodesplease' target='_new'>securecontainerrelease.com/nopincodesplease</a>),
  });
  const title = translate(titleKey);

  const actions = (
    <Box display="flex" flexDirection="reverse-row">
      <Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            showDialog(false);
            onCancel && onCancel(data.current);
          }}
        >
          {translate(cancelLabel)}
        </Button>
        <Button
          style={{marginLeft:'20px'}}
          variant="contained"
          color="primary"
          onClick={() => {
            showDialog(false);
            onOk && onOk(data.current);
          }}
        >
          {translate(okLabel)}
        </Button>
      </Box>
    </Box>
  );

  const showConfirmDialog = (d) => {
    setDialog({
      title,
      content,
      actions,
      canClose
    });
    data.current = d;
    showDialog(true);
  };

  return { showConfirmDialog };
};

export default useConfirmDialog;
